import React, { useCallback, useEffect, useState } from 'react';

import { CAREER_PLAN_STATUSES } from '@learned/constants';
import { ICareerPlan, IJobFamily, IJobLevelGroup, IJobProfile, IUser } from '@learned/types';
import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { isEmpty, slice } from 'lodash';
import { useFieldArray, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';

import { Button, ButtonVariant, ButtonSize } from '~/components/Buttons';
import { ICONS, ICON_SIZES } from '~/components/Icon';
import Modal from '~/components/Modal';
import ShowSpinnerIfLoading from '~/components/ShowSpinnerIfLoading';
import Switch from '~/components/Switch';
import { IAssignJobDetails } from '~/pages/UserPublicProfile/types';

import { getColumns, JOB_SORT_OPTIONS } from './columns';
import { JobDateSection } from './components/DateSection';
import {
  Footer,
  HeaderTitle,
  HeaderWrapper,
  ActionContainer,
  StyledButton,
  StyledTableList,
  SubTitle,
  StepLabel,
  SettingsContainer,
  ToggleContainer,
  JobSettingsWrapper,
} from './design';
import { Filters } from './filters';
import { useResolver } from './useResolver';

import { JOB_PROFILE_STATUSES } from '~/constants';
import useBoolState from '~/hooks/useBoolState';
import useDebounce from '~/hooks/useDebounce';
import { useMultiLangString } from '~/hooks/useMultiLangString';
import { usePagination } from '~/hooks/usePagination';
import { getSettingsRole } from '~/selectors/baseGetters';
import { getCareerPlans } from '~/services/careerPlans';
import { getJobFamilies } from '~/services/jobFamilies';
import { getJobLevelGroups } from '~/services/jobLevelGroups';
import { getJobProfiles } from '~/services/jobProfiles';
import getUserFullName from '~/utils/getUserFullName';

export interface IMultiSelectOption {
  id: string;
  key: string;
  name: string;
}

interface IAssignJobModalProps {
  employee: IUser;
  onClose: () => void;
  onSubmit: (selectedTeams: IAssignJobDetails[]) => Promise<void>;
}

enum SECTION {
  SELECT_JOB_SECTION = 1,
  SETTINGS_SECTION = 2,
}
export interface IAssignJobForm {
  jobs: {
    jobId: string;
    startDate?: Date;
    endDate?: Date;
    isPrimary: boolean;
  }[];
  primaryJob?: string;
}

const PAGE_SIZE = 10;
const DEFAULT_PAGINATION = { skip: 0, limit: PAGE_SIZE, index: 1 };

const initialFilters = {
  search: '',
  sortBy: JOB_SORT_OPTIONS.NAME_A_Z,
  pagination: DEFAULT_PAGINATION,
  jobFamilies: [],
  jobLevels: [],
};

export const AssignJobModal = ({ employee, onClose, onSubmit }: IAssignJobModalProps) => {
  const { i18n } = useLingui();
  const $loading = useBoolState();
  const { resolver } = useResolver();
  const getMultiLangString = useMultiLangString();
  const userRole = useSelector(getSettingsRole);
  const [primaryJob, setPrimaryJob] = useState<string | null>(null);
  const { pagination, changePagination, resetPagination } = usePagination(10);
  const [availableJobs, setAvailableJobs] = useState<Array<IJobProfile>>([]);
  const [paginatedJobs, setPaginatedJobs] = useState<Array<IJobProfile>>([]);
  const [filteredJobs, setFilteredJobs] = useState<Array<IJobProfile>>([]);
  const [currentFilters, setCurrentFilters] = useState<any>(initialFilters);
  const [currentSection, setCurrentSection] = useState<SECTION>(SECTION.SELECT_JOB_SECTION);
  const [jobFamilies, setJobFamilies] = useState<IMultiSelectOption[]>([]);
  const [jobLevels, setJobLevels] = useState<IMultiSelectOption[]>([]);
  const $applySameSettingsToggle = useBoolState(true);
  const debCurrentFilters = useDebounce(currentFilters, 300);

  const filterCounter = () => {
    const filters = [currentFilters.search, currentFilters.jobFamilies, currentFilters.jobLevels];
    return filters.filter((item) => !isEmpty(item)).length || undefined;
  };

  const filters = {
    isShowFilters: currentFilters.isShowFilters,
    search: currentFilters.search,
    setSearch: (value: string) =>
      setCurrentFilters((prevState: any) => ({
        ...prevState,
        search: value,
        pagination,
      })),
    onChangeFilter: (key: string, value: any) =>
      setCurrentFilters((prevState: any) => ({
        ...prevState,
        pagination,
        [key]: value,
      })),
    resetFilters: () => setCurrentFilters(initialFilters),
    jobFamilies: currentFilters.jobFamilies,
    jobLevels: currentFilters.jobLevels,
    filterCount: filterCounter(),
  };

  const getFilters = useCallback(() => {
    return {
      filters: {
        search: currentFilters.search,
        jobFamilies: currentFilters.jobFamilies
          ?.map(({ id }: IMultiSelectOption) => id)
          .filter(Boolean),
        jobLevels: currentFilters.jobLevels
          ?.map(({ id }: IMultiSelectOption) => id)
          .filter(Boolean),
      },
      options: {
        skip: currentFilters.pagination.skip,
        limit: currentFilters.pagination.limit,
        index: currentFilters.pagination.index,
        sortBy: currentFilters.sortBy,
      },
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debCurrentFilters]);

  const formControl = useForm<IAssignJobForm>({
    mode: 'all',
    resolver,
    defaultValues: {
      jobs: [],
    },
  });
  const { getValues, control, formState, trigger, setValue } = formControl;
  const { fields, remove, append } = useFieldArray({
    control,
    name: 'jobs',
  });

  useEffect(() => {
    const fetchJobProfiles = async () => {
      const [userCareerPlans, jobProfiles, jobFamilies, jobLevelGroups] = await Promise.all([
        getCareerPlans(
          { createdFor: employee.id, status: CAREER_PLAN_STATUSES.CURRENT },
          {},
          userRole,
        ),
        getJobProfiles({ status: JOB_PROFILE_STATUSES.ACTIVE.key, published: true }),
        getJobFamilies(),
        getJobLevelGroups(),
      ]);

      const careerPlans: ICareerPlan[] = Object.values(userCareerPlans);
      const allJobProfiles: IJobProfile[] = Object.values(jobProfiles);

      const availableJobProfiles = allJobProfiles.filter(
        (jobProfile: IJobProfile) =>
          !careerPlans.find((careerPlan: ICareerPlan) => careerPlan.jobProfile === jobProfile.id),
      );

      const jobFamiliesList = Object.values(jobFamilies?.data?.jobFamilies ?? {}) as IJobFamily[];
      const jobLevelGroupsList = Object.values(
        jobLevelGroups?.data?.jobLevelGroups ?? {},
      ) as IJobLevelGroup[];

      setAvailableJobs(availableJobProfiles);
      setJobFamilies(
        jobFamiliesList.map((jobFamily: IJobFamily) => {
          return {
            id: jobFamily.id,
            key: jobFamily.id,
            name: getMultiLangString(jobFamily.name ?? ''),
          };
        }),
      );
      setJobLevels(
        jobLevelGroupsList.map((jobLevel: IJobLevelGroup) => {
          return {
            id: jobLevel.id,
            key: jobLevel.id,
            name: getMultiLangString(jobLevel.name ?? ''),
          };
        }),
      );
    };

    fetchJobProfiles();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setValue('primaryJob', '');
  }, [$applySameSettingsToggle.value, setValue]);

  useEffect(() => {
    const { filters, options } = getFilters();

    let sortedJobs = [...availableJobs];

    if (filters.search) {
      sortedJobs = sortedJobs.filter((job) =>
        getMultiLangString(job?.name || '')
          ?.toLowerCase()
          .includes(filters.search.toLowerCase()),
      );
    }

    if (filters.jobFamilies.length > 0) {
      sortedJobs = sortedJobs.filter((job) =>
        filters.jobFamilies.find((id: string) => id === job.jobFamily),
      );
    }

    if (filters.jobLevels.length > 0) {
      sortedJobs = sortedJobs.filter((job) => {
        return !!filters.jobLevels.find((id: string) => id === job.jobLevelGroup?.id);
      });
    }

    setFilteredJobs(
      sortedJobs.sort((a, b) =>
        options.sortBy === JOB_SORT_OPTIONS.NAME_A_Z
          ? getMultiLangString(a.name).localeCompare(getMultiLangString(b.name))
          : getMultiLangString(b.name).localeCompare(getMultiLangString(a.name)),
      ),
    );

    const startIndex = (pagination.index - 1) * pagination.limit;
    sortedJobs = slice(sortedJobs, startIndex, startIndex + pagination.limit);

    setPaginatedJobs(sortedJobs);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagination, availableJobs, debCurrentFilters]);

  const handleResetFilter = (): void => {
    resetPagination();
    setCurrentFilters(initialFilters);
  };

  const onNextClick = () => {
    setCurrentSection(SECTION.SETTINGS_SECTION);
  };

  const getJobData = (
    job: {
      jobId: string;
      startDate?: Date;
      endDate?: Date;
      isPrimary: boolean;
    },
    jobSettings: {
      jobId: string;
      startDate?: Date;
      endDate?: Date;
      isPrimary: boolean;
    } | null,
    applySameSettings: boolean,
    primaryJob?: string,
  ) => ({
    ...jobSettings,
    id: job.jobId,
    isPrimary: applySameSettings ? !!primaryJob : primaryJob === job.jobId,
    startDate: applySameSettings ? jobSettings?.startDate : job.startDate,
    endDate: applySameSettings ? jobSettings?.endDate : job.endDate,
    jobProfile: availableJobs.find(({ id }) => job.jobId === id),
  });

  const onAssignClick = async () => {
    $loading.on();
    await trigger();

    const values = getValues();
    const { jobs, primaryJob } = values;

    if (!jobs.length) {
      return;
    }

    const applySameSettings = $applySameSettingsToggle.value;
    const jobSettings = applySameSettings ? jobs[0] : null;

    const jobList = jobs.map((job) => getJobData(job, jobSettings, applySameSettings, primaryJob));

    await onSubmit(jobList);

    $loading.off();
  };

  const handleItemSelect = (item: IJobProfile) => {
    const existingIndex = fields.findIndex(({ jobId }) => jobId === item.id);
    if (existingIndex !== -1) {
      remove(existingIndex);
    } else {
      const params = { jobId: item.id, isPrimary: false, startDate: new Date() };
      append(params);
    }
  };

  const handleCheckAll = () => {
    remove();
    if (fields.length !== filteredJobs.length) {
      append(
        filteredJobs.map((job) => {
          return {
            jobId: job.id,
            startDate: new Date(),
            isPrimary: false,
          };
        }),
      );
    }
  };

  const isDisabled = () => {
    return (
      $loading.value ||
      !(fields.length > 0) ||
      !!(currentSection === SECTION.SETTINGS_SECTION && !isEmpty(formState.errors))
    );
  };

  return (
    <Modal
      onClose={onClose}
      width={750}
      maxHeight={'900px'}
      contentStyles={{ padding: '24px 32px', margin: '0' }}
      isHideHeader
      hideFooter
      showDivider={false}
      overflow
    >
      <HeaderWrapper>
        <div>
          <HeaderTitle>
            <Trans>Assign jobs</Trans>
            {currentSection === SECTION.SETTINGS_SECTION && <span>({fields.length})</span>}
          </HeaderTitle>
          <SubTitle>
            <Trans>To</Trans>: {getUserFullName(employee)}
          </SubTitle>
        </div>
        <Button
          type="button"
          variant={ButtonVariant.ICON}
          size={ButtonSize.BIG}
          icon={ICONS.CLOSE}
          iconSize={ICON_SIZES.LARGE}
          onClick={onClose}
        />
      </HeaderWrapper>
      {currentSection === SECTION.SELECT_JOB_SECTION && (
        <ShowSpinnerIfLoading loading={$loading.value || false}>
          <StyledTableList
            columns={getColumns()}
            data={paginatedJobs}
            menuProps={{
              isMenuVisible: false,
            }}
            isLoading={false}
            multiSelectProps={{
              isMultiSelectVisible: true,
              multiSelect: {
                checkedCount: fields.length,
                isAllChecked: fields.length === filteredJobs.length,
                onSelectItem: (item) => handleItemSelect(item),
                isItemChecked: (item) => {
                  return !!fields?.find((job) => item.id === job.jobId);
                },
                onCheckAll: handleCheckAll,
              },
            }}
            placeholderProps={{
              emptyStateText: i18n._(t`No jobs...`),
            }}
            filtersProps={{
              isFiltered: !!filters.search,
              filters,
              isToggleHideFilterVisible: true,
              // @ts-ignore
              resetFilters: handleResetFilter,
              filterComponents: (
                <Filters filters={filters} jobFamilies={jobFamilies} jobLevels={jobLevels} />
              ),
            }}
            sortProps={{
              sortBy: currentFilters.sortBy,
              setSortBy: (sortBy: JOB_SORT_OPTIONS) =>
                setCurrentFilters({ ...currentFilters, sortBy }),
            }}
            paginationProps={{
              pagination,
              changePagination,
              totalCount: filteredJobs.length,
            }}
          />
        </ShowSpinnerIfLoading>
      )}
      {currentSection === SECTION.SETTINGS_SECTION && (
        <ShowSpinnerIfLoading loading={$loading.value || false}>
          <SettingsContainer>
            {fields.length > 1 && (
              <ToggleContainer>
                <Switch
                  onChange={$applySameSettingsToggle.toggle}
                  checked={$applySameSettingsToggle.value}
                />
                <Trans>Apply the same settings for all jobs</Trans>
              </ToggleContainer>
            )}
            <JobSettingsWrapper>
              {!$applySameSettingsToggle.value || fields.length === 1 ? (
                fields.map((job, index) => (
                  <JobDateSection
                    primaryJob={primaryJob}
                    setPrimaryJob={setPrimaryJob}
                    index={index}
                    formControl={formControl}
                    key={job.jobId}
                    jobProfile={filteredJobs.find(({ id }) => id === job.jobId)}
                  />
                ))
              ) : (
                <JobDateSection
                  index={0}
                  primaryJob={primaryJob}
                  setPrimaryJob={setPrimaryJob}
                  formControl={formControl}
                  jobProfiles={filteredJobs}
                  isGlobalSettings={$applySameSettingsToggle.value}
                />
              )}
            </JobSettingsWrapper>
          </SettingsContainer>
        </ShowSpinnerIfLoading>
      )}

      <Footer>
        <ActionContainer>
          <StyledButton
            disabled={$loading.value}
            onClick={onClose}
            variant={ButtonVariant.SECONDARY}
            size={ButtonSize.MEDIUM}
            label={i18n._(t`Cancel`)}
          />
          <StepLabel>
            Step {currentSection} <span>of 2</span>
          </StepLabel>
        </ActionContainer>
        <ActionContainer>
          {currentSection === SECTION.SETTINGS_SECTION && (
            <StyledButton
              disabled={$loading.value}
              onClick={() => setCurrentSection(SECTION.SELECT_JOB_SECTION)}
              icon={ICONS.BACK}
              variant={ButtonVariant.SECONDARY}
              size={ButtonSize.MEDIUM}
              label={i18n._(t`Back`)}
            />
          )}
          <StyledButton
            disabled={isDisabled()}
            onClick={() =>
              currentSection === SECTION.SELECT_JOB_SECTION ? onNextClick() : onAssignClick()
            }
            variant={ButtonVariant.PRIMARY}
            size={ButtonSize.MEDIUM}
            label={
              currentSection === SECTION.SELECT_JOB_SECTION ? i18n._(t`Next`) : i18n._(t`Assign`)
            }
          />
        </ActionContainer>
      </Footer>
    </Modal>
  );
};
