import React, { useState } from 'react';

import { IUser } from '@learned/types';
import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { isNumber } from 'lodash';
import moment from 'moment';
import { UseFormReturn } from 'react-hook-form';

import { Icon, ICONS } from '~/components/Icon';
import { Input } from '~/components/Input';
import Switch from '~/components/Switch';
import { UserAvatar } from '~/components/UserAvatar';

import {
  Container,
  DateContainer,
  DateSectionWrapper,
  JobTitle,
  RadioButtonWrapper,
  StyledDatePicker,
} from './design';

import useBoolState from '~/hooks/useBoolState';
import getUserFullName from '~/utils/getUserFullName';

import { IAssignEmployeeForm } from '../../types';

interface IJobDateSectionProps {
  index?: number;
  formControl: UseFormReturn<IAssignEmployeeForm>;
  employee?: IUser;
  isGlobalSetting?: boolean;
}

export const EmployeeDateSection = ({
  employee,
  formControl,
  index,
  isGlobalSetting,
}: IJobDateSectionProps) => {
  const { i18n } = useLingui();
  const { setValue, formState, trigger, watch } = formControl;

  const $isShowCalendarForStartDate = useBoolState();
  const $isShowCalendarForEndDate = useBoolState();
  const $isAllPrimary = useBoolState();
  const [globalStartDate, setGlobalStartDate] = useState<Date>(new Date());
  const [globalEndDate, setGlobalEndDate] = useState<Date>();

  const handleDateChange = (date: Date, isStartDate: boolean) => {
    if (isNumber(index)) {
      const fieldPath = `employees.${index}.${isStartDate ? 'startDate' : 'endDate'}` as const;
      setValue(fieldPath, date, { shouldDirty: true });

      const dateErrors = formState.errors?.employees?.[index];
      if (dateErrors?.startDate || dateErrors?.endDate) {
        trigger(`employees.${index}`);
      } else {
        trigger(fieldPath);
      }
    } else {
      isStartDate ? setGlobalStartDate(date) : setGlobalEndDate(date);
    }
  };

  const getFormattedDateToString = (isStartDate?: boolean) => {
    const startDate = isNumber(index) ? watch(`employees.${index}.startDate`) : globalStartDate;
    const endDate = isNumber(index) ? watch(`employees.${index}.endDate`) : globalEndDate;
    const datePattern = 'DD-MM-YYYY';

    if (isStartDate && startDate) {
      return moment(startDate).format(datePattern);
    }

    if (endDate) {
      return moment(endDate).format(datePattern);
    }

    return '';
  };

  const handleDateModalClose = async (date?: string | null, isStartingDate?: boolean) => {
    if (date) {
      handleDateChange(date as unknown as Date, !!isStartingDate);
    }

    await trigger();

    if (isStartingDate) {
      $isShowCalendarForStartDate.off();
    } else {
      $isShowCalendarForEndDate.off();
    }
  };

  return (
    <Container>
      {!isGlobalSetting && employee && (
        <JobTitle>
          <UserAvatar userId={employee.id} options={{ hideName: true }} />
          <span>
            <Trans>{getUserFullName(employee)}</Trans>
          </span>
        </JobTitle>
      )}
      <DateContainer>
        <DateSectionWrapper>
          <Input
            label={i18n._(t`Start date`)}
            placeholder={i18n._(t`Select date`)}
            leftIcon={<Icon icon={ICONS.CALENDAR} />}
            onClick={$isShowCalendarForStartDate.on}
            value={getFormattedDateToString(true)}
            // @ts-ignore
            error={formState.errors?.employees?.[index]?.startDate?.message}
          />
          {$isShowCalendarForStartDate.value && (
            <StyledDatePicker
              // @ts-ignore
              width={260}
              height="38px"
              onClose={(date) => handleDateModalClose(date, true)}
              placeholder={i18n._(t`Select date`)}
            />
          )}
        </DateSectionWrapper>
        <DateSectionWrapper>
          <Input
            label={i18n._(t`End date`)}
            placeholder={i18n._(t`Select date`)}
            leftIcon={<Icon icon={ICONS.CALENDAR} />}
            onClick={$isShowCalendarForEndDate.on}
            value={getFormattedDateToString()}
            // @ts-ignore
            error={formState.errors?.employees?.[index]?.endDate?.message}
          />
          {$isShowCalendarForEndDate.value && (
            <StyledDatePicker
              // @ts-ignore
              width={260}
              height="38px"
              placeholder={i18n._(t`Select date`)}
              onClose={(date) => handleDateModalClose(date)}
            />
          )}
        </DateSectionWrapper>
      </DateContainer>
      {
        <RadioButtonWrapper>
          <Switch
            onChange={(enabled: boolean) =>
              isNumber(index) && index >= 0
                ? setValue(`employees.${index}.isPrimary`, enabled)
                : $isAllPrimary.set(enabled)
            }
            checked={
              $isAllPrimary.value ||
              (isNumber(index) && index >= 0 && watch('employees').at(index)?.isPrimary)
            }
          />
          <span>Primary job</span>
        </RadioButtonWrapper>
      }
    </Container>
  );
};
