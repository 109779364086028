import React, { useEffect, useState } from 'react';

import {
  CAREER_PLAN_STATUSES,
  CONFIRMATION_MODAL_TYPE,
  CAREER_PLAN_SORT_OPTIONS,
} from '@learned/constants';
import { ICareerPlan, IUser } from '@learned/types';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import moment from 'moment';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import styled from 'styled-components';

import { ButtonVariant } from '~/components/Buttons';
import { ICONS } from '~/components/Icon';
import { AssignJobModal } from '~/components/Modals/AssignJobModal';
import { ConfirmationModal } from '~/components/Modals/ConfirmationModal';
import { TableList } from '~/components/TableList';
import BoxWithBorder from '~/components/UI/BoxWithBorder';
import { TableHeader } from '~/pages/Conversations/components/TableHeader';
import { PlaceholderCustom } from '~/pages/UserPublicProfile/tabs/components/PlaceholderCustom';

import { COLUMNS } from './columns';

import { ROLES } from '~/constants';
import routes from '~/constants/routes';
import useBoolState from '~/hooks/useBoolState';
import useDebounce from '~/hooks/useDebounce';
import { LS_KEYS, useLocalStorage } from '~/hooks/useLocalStorage';
import { useMultiLangString } from '~/hooks/useMultiLangString';
import { getSelectedRole, getUser } from '~/selectors/baseGetters';
import {
  changePrimaryCareerPlanForUser,
  createCareerPlans,
  removeCareerPlan,
  retrieveCareerPlans,
  updateCareerPlan,
} from '~/services/careerPlans';
import { COLORS } from '~/styles';

import { DateEditModal } from '../components/DateEditModal';

import type { FormDataType, IAssignJobDetails } from '../../types';

const HeaderWrapper = styled.div`
  margin: 24px 32px 0;
  border-bottom: 1px solid ${COLORS.BORDERS};
`;

const TableListStyled = styled(TableList)`
  padding: 0 32px 24px;
`;

const PAGE_SIZE = 3;
const DEFAULT_PAGINATION = { skip: 0, limit: PAGE_SIZE, index: 1 };
const LS_KEY = LS_KEYS.LS_USER_PUBLIC_PROFILE_TAB_CAREER_SECTION_JOBS;

const initialFilters = {
  sortBy: CAREER_PLAN_SORT_OPTIONS.NAME_A_Z,
  pagination: DEFAULT_PAGINATION,
};

const Jobs = ({ user }: { user: IUser }) => {
  const userId = user.id; // id of selected employee
  const { i18n } = useLingui();
  const [items, setItems] = useState<ICareerPlan[]>([]);
  const history = useHistory();
  const selectedRole = useSelector(getSelectedRole);
  const $isShowCreateJobModal = useBoolState(false);
  const $isShowEditDateModal = useBoolState(false);
  const $isShowDeleteModal = useBoolState(false);
  const formMethods = useForm<FormDataType>({
    mode: 'all',
    defaultValues: {
      startDate: '',
      endDate: '',
    },
  });
  const {
    handleSubmit,
    register,
    getValues,
    setValue,
    reset,
    formState: { errors },
  } = formMethods;
  const [jobToEdit, setJobToEdit] = useState<ICareerPlan>();
  const [jobToDelete, setJobToDelete] = useState<ICareerPlan>();
  const [currentFilters, setCurrentFilters] = useLocalStorage(LS_KEY, initialFilters);
  const { isShowFilters: _isShowFilters, ...debCurrentFilters } = useDebounce(currentFilters, 300); // isShowFilters does not affect on reFetch

  const [totalCount, setTotalCount] = useState(0);
  const $loading = useBoolState(false);
  const currentUser = useSelector(getUser);
  const getMultiLangString = useMultiLangString();

  const isAdmin = currentUser.isAdmin;

  const calcDuration = (items: ICareerPlan[]) => {
    const updatedCareerPlans = items.map((item) => {
      const startDate = item.startDate
        ? moment(item.startDate, 'YYYY-MM-DD')
        : moment(item.meta.createdDate, 'YYYY-MM-DD');
      const endDate = item.endDate ? moment(item.endDate, 'YYYY-MM-DD') : moment();
      const duration = moment.duration(endDate.diff(startDate));

      if (duration.asMilliseconds() < 0) {
        return { ...item, duration: '-' };
      }

      if (duration.years() >= 1) {
        return {
          ...item,
          duration:
            `${duration.years()} ${duration.years() === 1 ? t`year` : t`years`}` +
            (duration.months() >= 1
              ? ` ${duration.months()} ${duration.months() === 1 ? t`month` : t`months`}`
              : ''),
        };
      }

      if (duration.months() >= 1) {
        return {
          ...item,
          duration: `${duration.months()} ${duration.months() === 1 ? t`month` : t`months`}`,
        };
      }

      return {
        ...item,
        duration: `${duration.days()} ${duration.days() === 1 ? t`day` : t`days`}`,
      };
    });
    return updatedCareerPlans;
  };

  const fetchData = async (signal?: AbortSignal) => {
    $loading.on();
    const response = await retrieveCareerPlans(
      user.id,
      {
        skip: currentFilters.pagination.skip,
        limit: currentFilters.pagination.limit,
        sortBy: currentFilters.sortBy,
      }, // TODO fix this when service moved to TS

      // eslint-disable-next-line
      // @ts-ignore
      { ...(signal && { signal }) },
    );

    if (response) {
      const updatedCareerPlans = calcDuration(Object.values(response.data));
      updatedCareerPlans.forEach((cp) => {
        cp.name = getMultiLangString(cp.name);
      });
      setTotalCount(response.total);
      setItems(updatedCareerPlans as ICareerPlan[]);
    }

    $loading.off();
  };

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    fetchData(signal);

    return () => {
      controller.abort(); // cancel the request on component unmount
    };
    // eslint-disable-next-line
  }, [JSON.stringify(debCurrentFilters), userId]);

  const actionButton = {
    label: t`Assign`,
    onClick: $isShowCreateJobModal.on,
  };

  const onEdit = (job: ICareerPlan) => {
    setJobToEdit(job);
    setValue('startDate', job.startDate ? moment(job.startDate).format('DD-MM-YYYY') : '');
    setValue('endDate', job.endDate ? moment(job.endDate).format('DD-MM-YYYY') : '');
    $isShowEditDateModal.on();
  };

  const promptDelete = (job: ICareerPlan) => {
    setJobToDelete(job);
    $isShowDeleteModal.on();
  };

  const onDelete = async (job: ICareerPlan) => {
    $loading.on();
    await removeCareerPlan(job.id);

    // re-fetch data
    await fetchData();

    $loading.off();
  };

  const onChangePrimaryJob = async (job: ICareerPlan) => {
    $loading.on();
    await changePrimaryCareerPlanForUser(job.id);

    // re-fetch data
    await fetchData();

    $loading.off();
  };

  const onSaveDates = async (formData: FormDataType) => {
    $loading.on();

    const data = {
      startDate:
        formData.startDate === ''
          ? undefined
          : moment(formData.startDate, 'DD-MM-YYYY').format('YYYY-MM-DD'),
      endDate:
        formData.endDate === ''
          ? undefined
          : moment(formData.endDate, 'DD-MM-YYYY').format('YYYY-MM-DD'),
      status: moment(formData.endDate, 'DD-MM-YYYY').isBefore(moment())
        ? CAREER_PLAN_STATUSES.INACTIVE
        : jobToEdit?.status,
    };

    await updateCareerPlan(jobToEdit?.id, data);

    reset();
    $isShowEditDateModal.off();

    // re-fetch data
    await fetchData();

    $loading.off();
  };

  const onCancel = () => {
    reset();
    $isShowEditDateModal.off();
  };

  const onRowClick = (careerPlan: ICareerPlan) => {
    const roleId = careerPlan?.jobProfile;
    if (!roleId) {
      return;
    }
    const params = {
      roleId,
      isBackPath: true,
    };
    history.push(
      selectedRole === ROLES.USER
        ? routes.JOB_PROFILE.build({}, params)
        : routes.JOB_PROFILE_SUPERVISION_USER.build(
            {},
            {
              ...params,
              userId,
              isBackPath: true,
            },
          ),
    );
  };

  const createMenuItems = (item: ICareerPlan) => {
    // p.s. we do validation per option, because they have diff permission
    return [
      isAdmin &&
        item.status === CAREER_PLAN_STATUSES.CURRENT &&
        !item.primary && {
          label: i18n._(t`Make primary`),
          action: () => onChangePrimaryJob(item),
          icon: ICONS.STAR,
        },
      isAdmin && {
        label: i18n._(t`Edit dates`),
        action: () => onEdit(item),
        icon: ICONS.EDIT_PENCIL,
      },
      isAdmin && {
        label: i18n._(t`Delete`),
        action: () => promptDelete(item),
        icon: ICONS.DELETE_BIN,
        isWarning: true,
      },
    ].filter((i) => i) as unknown as {
      label: string;
      action: () => void;
      icon: ICONS;
      isWarning?: boolean;
    }[];
  };

  const onSubmit = async (selectedJobProfiles: IAssignJobDetails[]) => {
    const jobsData = selectedJobProfiles.map((job) => ({
      jobProfileId: job.id,
      status: CAREER_PLAN_STATUSES.CURRENT,
      userId: user.id,
      startDate: job.startDate,
      endDate: job.endDate,
      primary: job.isPrimary,
    }));

    await createCareerPlans(jobsData);

    // re-fetch data
    await fetchData();

    $isShowCreateJobModal.off();
  };

  return (
    <BoxWithBorder>
      <HeaderWrapper>
        <TableHeader
          filters={{ search: '', setSearch: () => {} }}
          actionButton={isAdmin ? actionButton : null}
          actionButtonVariant={ButtonVariant.SECONDARY}
          headerTitle={i18n._(t`Jobs`)}
          actionButtonIcon={undefined}
        />
      </HeaderWrapper>

      <TableListStyled
        data={items}
        columns={COLUMNS}
        onRowClick={onRowClick}
        sortProps={{
          sortBy: currentFilters.sortBy,
          setSortBy: (sortBy: CAREER_PLAN_SORT_OPTIONS) =>
            setCurrentFilters({ ...currentFilters, sortBy }),
        }}
        paginationProps={{
          pagination: currentFilters.pagination,
          changePagination: ({ skip, limit, index }) =>
            setCurrentFilters({
              ...currentFilters,
              pagination: { ...currentFilters.pagination, skip, limit, index },
            }),
          totalCount,
        }}
        isLoading={$loading.value}
        actionButton={isAdmin ? actionButton : undefined}
        menuProps={{
          createMenuItems,
          isMenuVisible: true,
        }}
        isLeftCornerRounded={true}
        placeholder={<PlaceholderCustom text={i18n._(t`No jobs`)} />}
      />

      {$isShowCreateJobModal.value && (
        <AssignJobModal employee={user} onClose={$isShowCreateJobModal.off} onSubmit={onSubmit} />
      )}

      {$isShowEditDateModal.value && (
        <DateEditModal
          onClose={onCancel}
          onSave={onSaveDates}
          submit={handleSubmit}
          register={register}
          getValues={getValues}
          setValue={setValue}
          errors={errors}
        />
      )}

      {$isShowDeleteModal.value && (
        <ConfirmationModal
          title={i18n._(t`Delete job`)}
          description={i18n._(t`Are you sure you want to delete this job?`)}
          type={CONFIRMATION_MODAL_TYPE.WARNING}
          onClose={$isShowDeleteModal.off}
          onSubmit={() => onDelete(jobToDelete as ICareerPlan)}
        />
      )}
    </BoxWithBorder>
  );
};

export { Jobs };
